import * as React from "react"
import { Helmet } from "react-helmet";

import { Navigation, Content, Banner } from "../components";
import "../styles/main.scss";
import splash from "../images/line.png";

import flag from "../images/icons/flag.svg";
import people from "../images/icons/people.svg";
import map from "../images/icons/map.svg";

// --------------------------------------------------------------------------------
// BANNER
// --------------------------------------------------------------------------------
function PageBanner(): JSX.Element {
    const title = <>Engage your stakeholders with data&#8209;driven conversations</>;

    const subtitle = <>
        <p>Take the lead in conversations.<br />
            Think strategically.<br />
            Justify your opinions with facts.</p>
    </>;

    // const sparkle = <img className="w-100 h-100 border ps-2 py-2 bg-white" src={splash} width="417px" height="288px" alt="" aria-label="" />;
    const sparkle = <div className="text-md-end">
        <img src={splash} alt="Sample line graph" className="bg-white border mw-100 xmh-100" />
    </div>;

    return <Banner
        title={title}
        subtitle={subtitle}
        action={{ to: "/contact-us/", caption: "Request a demo" }}
        sparkle={sparkle}
    />;
}

// --------------------------------------------------------------------------------
// Introduction
// --------------------------------------------------------------------------------
function PageIntro(): JSX.Element {
    const intro = <>
        <h2>Work with the data your already have</h2>
        <p>Qebal works with the data you already have, helping you to deliver insight and advice to your stakeholders.
        </p>
        <p>Combine data from different sources and from across your organisation.
        </p><p>Generate HR metrics to create a snapshot of your stakeholders’ people and their performance.
        </p><p>Provide reports with meaningful and valuable analysis.</p>
    </>;

    return <div className="row row-cols-1 row-cols-sm-1 pb-4 gy-4">
        <div className="col text-sm-center px-4 ">
            {intro}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Body
// --------------------------------------------------------------------------------
function PageBody(): JSX.Element {
    const body1 = <>
        <h2>People analytics, metrics and insights</h2>
        <p>Key metrics help you become more strategic and an ally of your business stakeholders.</p>
        <p>Pre-built reports with the HR metrics important to you help you offer insight in their team's performance, key strengths and opportunities for improvement.</p>
    </>;

    const body2 = <>
        <h2>Workforce data for decision makers</h2>
        <p>
            Identify workforce opportunities and risks that need to be communicated to your stakeholders.
            Provide clear overviews with the supporting information necessary to explain complex situations.
        </p>
        <p>
            Move from opinion to evidence based decision making with insightful dashboards and reports.
        </p>
    </>;

    return <div className="row row-cols-1 row-cols-sm-2 pb-4 gy-4">
        <div className="col px-4">
            {body1}
        </div>
        <div className="col px-4">
            {body2}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Features
// --------------------------------------------------------------------------------
function PageFeatures(): JSX.Element {
    const block1 = <>
        <div className="px-0 py-4">
            <img src={people} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />

            <h2>Analytics platform built for HR</h2>
            <ul>
                <li>No technical or data skills required</li>
                <li>In-built HRBP dashboards</li>
                <li>Easy to understand HR analytics</li>
                <li>Ready-made stakeholder reports</li>
                <li>Pre-calculated HR metrics</li>
                <li>Create your own reports</li>
            </ul>
        </div>
    </>;

    const block2 =
        <div className="px-0 py-4">
            <img src={flag} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />
            <h2>Compare historical data and make predictions</h2>
            <ul>
                <li>Quickly import, combine and compare data</li>
                <li>Easily identify patterns, trends and problems</li>
                <li>Interactive filters and drill-downs to analyse data</li>
                <li>Predict what-if outcomes and monitor improvement</li>
                <li>Create or re-use ad-hoc reports</li>
            </ul>
        </div>;

    const block3 = <>
        <div className="px-0 py-4">
            <img src={map} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />

            <h2>Manage stakeholder conversations about People</h2>
            <ul>
                <li>Confidently talk about HR and financial data</li>
                <li>Strategically lead discussions using analysis</li>
                <li>Schedule BAU reports and manage expectations</li>
                <li>Instantly respond to common HR data requests</li>
                <li>Show audience how to improve people decisions</li>
            </ul>
        </div>
    </>;

    return <div className="row row-cols-1 row-cols-sm-3">
        <div className="col qbl-block-1 px-4">
            {block1}
        </div>
        <div className="col qbl-block-2 px-4">
            {block2}
        </div>
        <div className="col qbl-block-1 px-4">
            {block3}
        </div>
    </div>;
}

const PeopleAnalytics = () =>
    <>
        <Helmet title="Qebal People Analytics" htmlAttributes={{ lang: 'en' }} />
        <Navigation />
        <Content>
            <div className="container-lg">
                <PageBanner />
                <PageIntro />
                <PageBody />
                <PageFeatures />
            </div>
        </Content>
    </>;

export default PeopleAnalytics;